import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import {config} from 'react-spring'
import {Spring} from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";

const Img = styled.img`
        vertical-align: middle;
    `;
const ImgColumn = styled(Col)`
        display: flex;
        align-items: center;
        justify-content: center;
    `;

const ContentColumn = styled(Col)`
        padding: 1rem 3rem 1rem 3rem !important;
    `;

const ListItem = styled.li`
      text-align: left;
      padding-left: 10px;
`

const H4 = styled.h4`
    line-height: 1.6em;
    text-align: left;
`;

const P = styled.p`
    text-align: left;
`;

const Scopes = (props) => {

  const lgImg = 4;
  const lgContent = 12 - lgImg;

  const mdImg = 5;
  const mdContent = 12 - mdImg;

  const smImg = 12;

  return (
      <React.Fragment>
        {props.data.map(
            (scope, index) => {
              return index % 2 ?
                  (<AnimatedRow noGutters="true" style={{minHeight: "200px"}}>
                    <ImgColumn noGutters={true} lg={lgImg} md={mdImg}
                               sm={smImg}>
                      <Img alt={scope.imageAlt} src={scope.image}/>
                    </ImgColumn>
                    <ContentColumn lg={lgContent} md={mdContent} sm={smImg}>
                      <H4>{scope.header}</H4>
                      <P>{scope.content}</P>
                      <ul>
                        {                          
                          scope.list?.map(item => {
                          return (
                            <ListItem>
                              {item}
                            </ListItem>
                          )
                        })}                        
                      </ul>
                    </ContentColumn>
                  </AnimatedRow>) :
                  (<AnimatedRow noGutters={true} style={{minHeight: "200px"}}
                                className="mt-4" reverseAnimation>
                    <ContentColumn lg={lgContent} md={mdContent} sm={smImg}
                                   className="col-sm-push-12">
                      <H4>{scope.header}</H4>
                      <P>{scope.content}</P>
                      {(()=>{if(scope.listTitle) return <h5>{scope.listTitle}</h5>})()}
                      <ul>
                        {                          
                          scope.list?.map(item => {
                          return (
                            <ListItem>
                              {item}
                            </ListItem>
                          )
                        })}                        
                      </ul>
                    </ContentColumn>
                    <ImgColumn lg={lgImg} md={mdImg} sm={smImg}
                               className="order-md-last order-sm-first order-first">
                      <Img alt={scope.imageAlt} src={scope.image}/>
                    </ImgColumn>
                  </AnimatedRow>)
            }
        )
        }
      </React.Fragment>
  )
}


const BoxedRow = styled(Row)`
    transition: box-shadow 0.5s;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);    
    background-color: #fff;
    border-radius: 5px;
    margin: 1rem 10px 10px 10px;
    padding: 0 1rem 0 1rem;
`
const AnimatedRow = (props) => {
  const xTranslation = props.reverseAnimation ? "-200px" : "200px"
  var executed = false
  return (
      <VisibilitySensor partialVisibility offset={{ bottom: 100, top: 100 }}>
          {({ isVisible }) => (
              <Spring delay={200} config={config.slow} to={
                  {
                      opacity: isVisible || executed ? 1 : 0,
                      transform: isVisible || executed
                          ? "translateX(0)"
                          : `translateX(${xTranslation})`
                  }}>
                  {(transformation) => {
                      if (!executed)
                          executed = transformation.opacity > 0.1
                      return (
                          <BoxedRow noGutters="true" style={{ overflow: "hidden", minHeight: "200px", ...transformation }}>
                              {props.children}
                          </BoxedRow>
                      )
                  }}
              </Spring>
          )}
      </VisibilitySensor>
  )
}

export default Scopes